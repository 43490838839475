<template>
  <div class="page">
    <Tables
      ref="xTable"
      :tableName="tableName"
      :tableData="tableData"
      :totalPage="totalPage"
      :isLoading="isLoading"
      :searchFormData="searchFormData"
      :showAdd="$hasAccess('system.user/add')"
      addName="添加账号"
      :showRefresh="true"
      :showSearch="true"
      @getList="getList"
      @addItemInTable="addUser"
      @clearSearch="clearSearch"
    >

      <!--搜索自定义内容-->
      <el-form-item slot="search-item">
        <el-input
          v-model="searchFormData.username"
          size="small"
          clearable
          placeholder="按账号名搜索"
        ></el-input>
      </el-form-item>

      <!--自定义表格内容-->
      <vxe-table-column
        slot="table-item"
        field="username"
        title="账号名"
        align="center"
      />
      <vxe-table-column
        slot="table-item"
        field="realname"
        title="账号昵称"
        align="center"
      />
      <vxe-table-column
        slot="table-item"
        title="角色"
        align="center"
      >
        <template v-slot="{ row }">
          {{row.role.role_name}}
        </template>
      </vxe-table-column>
      <vxe-table-column
              slot="table-item"
              field="mch_id"
              title="商户号"
              align="center"
      />
      <vxe-table-column
        slot="table-item"
        field="login_time"
        title="最后登录"
        align="center"
      />
      <vxe-table-column
        slot="table-item"
        title="操作"
        width="180"
        align="center"
      >
        <template v-slot="{ row }">
          <el-button
            size="small"
            icon="el-icon-edit"
            plain
            @click="editUser(row)"
            v-if="$hasAccess('system.user/edit')"
          >编辑
          </el-button>
          <el-button
            size="small"
            icon="el-icon-delete"
            plain
            @click="deleteUser(row)"
            :disabled="row.role.pid === 0"
          >删除
          </el-button>
        </template>
      </vxe-table-column>
    </Tables>

    <!-- 添加 -->
    <el-dialog
      title="添加"
      :visible.sync="addDialogShow"
      width="500px"
    >
      <el-form
        :model="addDialogFormData"
        :rules="rules"
        ref="addDialogForm"
        label-width="100px"
        class="form"
      >
        <el-form-item
          label="角色"
          prop="role_id"
        >
          <el-select
            v-model="addDialogFormData.role_id"
            placeholder="请选择所属角色"
            size="small"
            clearable
          >
            <el-option
              v-for="item in roleList"
              :label="item.role_name"
              :value="item.role_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="账号名"
          prop="username"
        >
          <el-input
            v-model="addDialogFormData.username"
            placeholder="请输入账号名"
            size="small"
            style="width: 80%"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="密码"
          prop="password"
        >
          <el-input
            v-model="addDialogFormData.password"
            placeholder="请输入密码"
            show-password
            size="small"
            style="width: 80%"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="账号昵称"
          prop="realname"
        >
          <el-input
            v-model="addDialogFormData.realname"
            placeholder="请输入账号昵称"
            size="small"
            style="width: 80%"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="手机号"
          prop="phone"
        >
          <el-input
            v-model="addDialogFormData.phone"
            placeholder="请输入手机号"
            maxlength="11"
            size="small"
            style="width: 80%"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input
            v-model="addDialogFormData.email"
            placeholder="请输入邮箱"
            size="small"
            style="width: 80%"
          ></el-input>
        </el-form-item>
        <el-form-item label="开放小区">
          <el-select
            v-model="groupIds"
            size="small"
            style="width: 80%"
            multiple
            filterable
            remote
            reserve-keyword
            clearable
            placeholder="空则全部开放"
            :remote-method="remoteGroup"
            :loading="groupLoading">
            <el-option
              v-for="item in groupList"
              :key="item.group_id"
              :label="item.group_name"
              :value="item.group_id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          size="small"
          plain
          icon="el-icon-check"
          @click="handleConfirm('addDialogForm','add')"
          v-if="$hasAccess('system.user/add')"
        >保 存
        </el-button>
        <el-button
          size="small"
          plain
          @click="closeDialog"
          icon="el-icon-close"
        >取 消</el-button>
      </div>
    </el-dialog>

    <!-- 编辑 -->
    <el-dialog
      title="编辑"
      :visible.sync="editDialogShow"
      width="500px"
    >
      <el-form
        :model="editDialogFormData"
        :rules="rules"
        ref="editDialogForm"
        label-width="100px"
        class="form"
      >
        <el-form-item
          label="角色"
          prop="role_id"
        >
          <el-select
            v-model="editDialogFormData.role_id"
            placeholder="请选择所属角色"
            size="small"
            clearable
          >
            <el-option
              v-for="item in roleList"
              :label="item.role_name"
              :value="item.role_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="账号名"
          prop="username"
        >
          <el-input
            v-model="editDialogFormData.username"
            placeholder="请输入账号名"
            size="small"
            style="width: 80%"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input
            v-model="editDialogFormData.password"
            placeholder="不输入维持原密码"
            show-password
            size="small"
            style="width: 80%"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="账号昵称"
          prop="realname"
        >
          <el-input
            v-model="editDialogFormData.realname"
            placeholder="请输入账号昵称"
            size="small"
            style="width: 80%"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="手机号"
          prop="phone"
        >
          <el-input
            v-model="editDialogFormData.phone"
            placeholder="请输入手机号"
            maxlength="11"
            size="small"
            style="width: 80%"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input
            v-model="editDialogFormData.email"
            placeholder="请输入邮箱"
            size="small"
            style="width: 80%"
          ></el-input>
        </el-form-item>
        <el-form-item label="开放小区">
          <el-select
            v-model="groupIds"
            size="small"
            style="width: 80%"
            multiple
            filterable
            remote
            reserve-keyword
            clearable
            placeholder="空则全部开放"
            :remote-method="remoteGroup"
            :loading="groupLoading">
            <el-option
              v-for="item in groupList"
              :key="item.group_id"
              :label="item.group_name"
              :value="item.group_id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          size="small"
          plain
          icon="el-icon-check"
          @click="handleConfirm('editDialogForm','edit')"
          v-if="$hasAccess('system.user/edit')"
        >保 存
        </el-button>
        <el-button
          size="small"
          plain
          @click="closeDialog"
          icon="el-icon-close"
        >取 消</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import Tables from '@/components/tables'

export default {
  name: 'system-user-list',
  components: {
    Tables
  },
  data () {
    return {
      tableName: '账号管理',
      tableData: [],
      totalPage: 0,
      isLoading: false,
      roleList: [],
      searchFormData: {
        username: ''
      },
      addDialogShow: false,
      addDialogFormData: {
        role_id: '',
        username: '',
        realname: '',
        password: '',
        phone: '',
        email: '',
        group_id: ''
      },
      editDialogShow: false,
      editDialogFormData: {
        user_id: '',
        role_id: '',
        username: '',
        realname: '',
        password: '',
        phone: '',
        email: '',
        group_id: '',
        status: '1'
      },
      rules: {
        role_id: [
          { required: true, message: '请选择所属角色！', trigger: 'blur' }
        ],
        username: [
          { required: true, message: '请输入账号名！', trigger: 'blur' },
          { min: 3, max: 30, message: '账号名长度在3到30个字符!', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入账号密码！', trigger: 'blur' }
        ],
        realname: [
          { required: true, message: '请输入账号昵称！', trigger: 'blur' },
          { min: 2, max: 30, message: '账号名长度在3到30个字符!', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入手机号！', trigger: 'blur' },
          { min: 11, max: 11, message: '请输入正确位数的手机号！', trigger: 'blur' }
        ],
        email: [
          { required: false, message: '请输入邮箱！', trigger: 'blur' }
        ]
      },
      groupList: [],
      groupIds: [],
      groupLoading: false
    }
  },
  methods: {
    // 拉取数据
    getList (currentPage, pageSize, searchFormData) {
      const params = {
        token: this.$store.state.user.token,
        page: currentPage,
        size: pageSize
      }
      let searchParams = {
        username: this.searchFormData.username
      }
      this.isLoading = true
      // 获取账号列表
      this.$api.System.GetUserList(params, searchParams).then(res => {
        this.totalPage = res.total
        this.tableData = res.data
        this.isLoading = false
      })
    },
    clearSearch () {
      this.searchFormData = {
        username: ''
      }
    },
    // 重置表单数据
    resetFormData () {
      this.addDialogFormData = {
        role_id: '',
        username: '',
        realname: '',
        password: '',
        phone: '',
        email: '',
        group_id: ''
      }
      this.editDialogFormData = {
        role_id: '',
        username: '',
        realname: '',
        password: '',
        phone: '',
        email: '',
        group_id: ''
      }
      this.groupIds = []
      if (this.$refs.addDialogForm) {
        this.$refs.addDialogForm.resetFields()
      }
      if (this.$refs.editDialogForm) {
        this.$refs.editDialogForm.resetFields()
      }
    },
    // 删除账号
    deleteUser (row) {
      this.$confirm('确定要删除该账号吗', '提示', {
        cancelButtonClass: 'btn-custom-cancel',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {
          token: this.$store.state.user.token,
          user_id: row.user_id
        }
        this.$api.System.DeleteUser(params).then(res => {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          })
          this.getList(0, 10, '')
        })
      })
    },
    // 新增账号
    addUser () {
      this.resetFormData()
      const params = {
        token: this.$store.state.user.token,
        page: 1,
        size: 200
      }
      // 需要拉取角色表
      this.$api.System.GetRoleList(params).then(res => {
        this.roleList = res.map(item => {
          item.role_name = item.role_name.replace(new RegExp(/&nbsp;/g), '')
          return item
        })
        this.addDialogShow = true
      })
    },
    // 编辑账号
    async editUser (row) {
      this.resetFormData()
      const params = {
        token: this.$store.state.user.token,
        user_id: row.user_id
      }
      // 获取账号详情
      this.$api.System.GetUserDetail(params).then(res => {
        this.editDialogFormData = {
          role_id: res.role.role_id,
          user_id: res.user_id,
          username: res.username,
          realname: res.realname,
          password: '',
          phone: res.phone,
          email: res.email,
          status: res.status,
          group_id: ''
        }
        if (res.group_id) {
          const params = {
            token: this.$store.state.user.token,
            size: 1000
          }
          this.$api.Device.GetGroupList(params).then(gres => {
            this.groupList = gres.data
            const groupIds = res.group_id.toString().split(',')
            for (let i = 0; i < groupIds.length; i++) {
              groupIds[i] = parseInt(groupIds[i])
            }
            this.groupIds = groupIds
          })
        }
        return this.$api.System.GetRoleList(params)
      }).then(res => {
        this.roleList = res.map(item => {
          item.role_name = item.role_name.replace(new RegExp(/&nbsp;/g), '')
          return item
        })
        this.editDialogShow = true
      })
    },
    // 确认
    handleConfirm (formName, type) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (type === 'add') {
            this.addDialogFormData.group_id = this.groupIds.join(',')
            const form = this.addDialogFormData
            const params = {
              token: this.$store.state.user.token,
              form: JSON.stringify(form)
            }
            this.$api.System.AddUser(params).then(res => {
              this.$notify({
                title: '成功',
                message: '添加成功',
                type: 'success'
              })
              this.$refs.xTable.refreshTable()
              this.addDialogShow = false
            })
          } else if (type === 'edit') {
            this.editDialogFormData.group_id = this.groupIds.join(',')
            const form = this.editDialogFormData
            const params = {
              token: this.$store.state.user.token,
              form: JSON.stringify(form)
            }
            this.$api.System.EditUser(params).then(res => {
              this.$notify({
                title: '成功',
                message: '修改成功',
                type: 'success'
              })
              this.$refs.xTable.refreshTable()
              this.editDialogShow = false
            })
          }
        }
      })
    },
    // 关闭弹窗
    closeDialog () {
      this.addDialogShow = false
      this.editDialogShow = false
      this.resetFormData()
    },
    // 小区
    async remoteGroup (query) {
      if (query !== '') {
        this.groupLoading = true
        const params = {
          token: this.$store.state.user.token
        }
        const res = await this.$api.Device.GetGroupList(params, {
          group_name: query
        })
        this.groupList = res.data
        this.groupLoading = false
      } else {
        this.groupList = []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.normal {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ee8812;

  .circle {
    width: 10px;
    height: 10px;
    background: #ee8812;
    border-radius: 100%;
    margin-right: 10px;
  }
}

.hide {
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray;

  .circle {
    width: 10px;
    height: 10px;
    background: gray;
    border-radius: 100%;
    margin-right: 10px;
  }
}
</style>
